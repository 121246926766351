@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@import "tailwindcss/base";
@import "tailwindcss/components";

/* Additional styles */
@import "additional-styles/utility-patterns.css";
@import "additional-styles/flatpickr.css";

@import "tailwindcss/utilities";
